div.container > div.item {
    padding-top:5px;
    padding-bottom:15px;
    padding-left:10px;
    margin-bottom:20px;
}

div.container > div.item:nth-of-type(odd) {
    background: #fffafa;
}

div.container > div.item:nth-of-type(even) {
    background: #f8f8ff;
}

.english-text {
    max-height: 50px;
    margin-right: 5px;
    margin-bottom: 10px;
    overflow-Y: auto;
    font-size: smaller;
}