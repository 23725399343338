.button {
    padding:0!important;
    width: 30px!important;
    height: 30px!important;
    min-width: 0px!important;
    min-height: 0px!important;
    background-color: #fff!important;
}

.button:hover {
    background-color: #f5f5f5!important;
}